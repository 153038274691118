export default Object.freeze([
	{
		id: 601,
		description: 'General de Ley Personas Morales',
	},
	{
		id: 603,
		description: 'Personas Morales con Fines no Lucrativos',
	},
	{
		id: 605,
		description: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
	},
	{
		id: 606,
		description: 'Arrendamiento',
	},
	{
		id: 607,
		description: 'Régimen de Enajenación o Adquisición de Bienes',
	},
	{
		id: 608,
		description: 'Demás ingresos',
	},
	{
		id: 610,
		description: 'Residentes en el Extranjero sin Establecimiento Permanente en México',
	},
	{
		id: 611,
		description: 'Ingresos por Dividendos (socios y accionistas)',
	},
	{
		id: 612,
		description: 'Personas Físicas con Actividades Empresariales y Profesionales',
	},
	{
		id: 614,
		description: 'Ingresos por intereses',
	},
	{
		id: 615,
		description: 'Régimen de los ingresos por obtención de premios',
	},
	{
		id: 616,
		description: 'Sin obligaciones fiscales',
	},
	{
		id: 620,
		description: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
	},
	{
		id: 621,
		description: 'Incorporación Fiscal',
	},
	{
		id: 622,
		description: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
	},
	{
		id: 623,
		description: 'Opcional para Grupos de Sociedades',
	},
	{
		id: 624,
		description: 'Coordinados',
	},
	{
		id: 625,
		description: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
	},
	{
		id: 626,
		description: 'Régimen Simplificado de Confianza',
	},
])
