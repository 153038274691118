<template>
	<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
		<div class="card" :class="$style.container">
			<div class="row">
				<div class="col-md-12">
					<div class="text-center mb-2">
						<img src="resources/images/logoS2R.png" class="mr-2" height="150" />
					</div>
					<div class="text-dark font-size-24 text-center">
						<strong>Kiosko de facturación</strong>
					</div>
					<a-divider />
				</div>
			</div>
			<div class="row" v-if="!hasInvoice">
				<div class="col-md-12">
					<a-form :form="verificationForm" @submit="handleSubmitVerification">
						<div class="row">
							<div class="col-6">
								<a-form-item label="Número de ticket">
									<a-input class="text-uppercase" :disabled="validTicket" v-decorator="[
										'id',
										{
											rules: [
												{
													required: true,
													message: 'Ingresa número de ticket',
												},
											],
										},
									]" autocomplete="off" />
								</a-form-item>
							</div>
							<div class="col-6">
								<a-form-item label="Clave para facturar">
									<a-input :disabled="validTicket" v-decorator="['validator']" autocomplete="off" />
								</a-form-item>
							</div>
							<div class="col-12 text-right" v-if="!validTicket">
								<a-divider />
								<button class="btn btn-info" @click="resetForm($event, 'verification')" :loading="loading">
									Limpiar
								</button>
								<button htmlType="submit" class="ml5 btn btn-success" :loading="loading">
									Validar
								</button>
							</div>
						</div>
					</a-form>
					<div v-if="validTicket">
						<a-divider />
						<a-form :form="form" @submit="handleSubmit">
							<div class="row">
								<div class="col-4">
									<a-form-item label="RFC">
										<a-input class="text-uppercase" autocomplete="off" v-decorator="[
											'rfc',
											{
												rules: [
													{
														required: true,
														message: 'Ingresa tu RFC',
													},
												],
											},
										]" @change="onChangeRFC" />
									</a-form-item>
								</div>
								<div class="col-4">
									<a-form-item label="Código Postal">
										<a-input class="text-uppercase" autocomplete="off" v-mask="'#####'" v-decorator="[
											'codigo_postal',
											{
												rules: [
													{
														required: true,
														message: 'Ingresa tu Código Postal',
													},
												],
											},
										]" />
									</a-form-item>
								</div>
								<div class="col-4">
									<a-form-item label="Email">
										<a-input class="text-lowercase" autocomplete="off" v-decorator="[
											'email',
											{
												rules: [
													{
														required: true,
														message: 'Ingresa tu Email',
													},
													{
														email: true,
														message: 'Ingresa email válido',
													},
												],
											},
										]" />
									</a-form-item>
								</div>
								<div class="col-12">
									<a-form-item label="Régimen Fiscal">
										<a-select show-search v-decorator="[
											'regimen_fiscal',
											{
												rules: [
													{
														required: true,
														message: 'Campo requerido.',
													},
												],
											},
										]">
											<a-select-option v-for="(element, index) in taxRegimes" :key="index" :value="element.id"> {{ element.id }} - {{ element.description }} </a-select-option>
										</a-select>
									</a-form-item>
								</div>
								<div class="col-4">
									<a-form-item label="Uso de CFDI">
										<a-select show-search v-decorator="[
											'uso_cfdi',
											{
												rules: [
													{
														required: true,
														message: 'Campo requerido.',
													},
												],
											},
										]">
											<a-select-option v-for="(element, index) in cfdiUses" :key="index" :value="element.id"> {{ element.id }} - {{ element.description }} </a-select-option>
										</a-select>
									</a-form-item>
								</div>
								<div class="col-8">
									<a-form-item label="Razón Social">
										<a-input class="text-uppercase" autocomplete="off" v-decorator="[
											'razon_social',
											{
												rules: [
													{
														required: true,
														message: 'Campo requerido.',
													},
												],
											},
										]" />
									</a-form-item>
								</div>
							</div>
							<a-divider />
							<div class="col-12 text-right">
								<button class="btn btn-info" @click="resetForm($event, 'billing')" :loading="loading">
									Limpiar
								</button>
								<button htmlType="submit" class="ml5 btn btn-success" :loading="loading">
									Solicitar factura
								</button>
							</div>
						</a-form>
					</div>
				</div>
			</div>
			<div class="row" v-else>
				<div class="col-md-12 text-center">
					<h4 class="text-muted">Tu factura ha sido enviada por correo electrónico.</h4>
					<button class="btn btn-info" @click="downloadBill('xml')">
						Descargar XML
					</button>
					<button class="ml5 btn btn-info" @click="downloadBill('pdf')">
						Descargar PDF
					</button>
				</div>
				<div class="col-md-12 text-center" style="padding-top: 10px;">
					<button class="btn btn-primary" @click="initModule">
						Generar nueva factura
					</button>
				</div>
			</div>
		</div>
	</a-spin>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { mask } from 'vue-the-mask'
import utilities from '@/services/utilities'
import { taxRegimes, cfdiUses, regexp } from '@/constants'
import Swal from 'sweetalert2'
import _ from 'lodash'

export default {
	name: 'billingComponent',
	directives: {
		mask,
	},
	computed: {
		...mapState(['settings']),
		...mapGetters('billing', ['invoice', 'spinnerLoaderLabel', 'spinnerLoader']),
		loading() {
			return this.$store.state.billing.loading
		},
	},
	data: function () {
		return {
			taxRegimes,
			cfdiUses,
			form: this.$form.createForm(this),
			verificationForm: this.$form.createForm(this),
			validTicket: false,
			hasInvoice: false,
			bill: {
				pdf: '',
				xml: '',
			},
		}
	},
	methods: {
		initModule() {
			window.location.reload()
		},
		onChangeRFC(e) {
			let rfc = utilities.objectValidate(e, 'target.value', '').toUpperCase()
			if (new RegExp(regexp.rfc).test(rfc)) {
				this.$store.dispatch('billing/SEARCH_RFC', {
					rfc,
				}).then(response => {
					if (utilities.objectValidate(response, 'data.rfc', false)) {
						this.form.setFieldsValue({
							rfc: utilities.objectValidate(response, 'data.rfc', ''),
							codigo_postal: utilities.objectValidate(response, 'data.zip_code', ''),
							email: utilities.objectValidate(response, 'data.email', ''),
							razon_social: utilities.objectValidate(response, 'data.business_name', ''),
						})
					}
				})
			}
		},
		handleSubmitVerification(e) {
			e.preventDefault()
			this.verificationForm.validateFields((err, values) => {
				if (!err) {
					this.$store.dispatch('billing/VALIDATE_TICKET', values).then(response => {
						if (response.success) {
							this.validTicket = true
							Swal.fire({
								title: 'Facturación',
								text: 'Ingresa tu información fiscal para generar tu factura',
								icon: 'success',
								confirmButtonText: 'Ok',
							})
						} else {
							this.validTicket = false
							Swal.fire({
								title: 'Facturación',
								text: utilities.objectValidate(response, 'data.message', 'Factura generada con éxito.'),
								icon: 'error',
								confirmButtonText: 'Ok',
							})
						}
					}).catch(error => {
						Swal.fire({
							title: 'Facturación',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al validar el ticket. Solicita apoyo vía whatsapp al 55 6382 5173'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						this.validTicket = false
					})
				}
			})
		},
		handleSubmit(e) {
			e.preventDefault()
			let verificationValues = undefined
			this.verificationForm.validateFields((err, values) => {
				verificationValues = _.cloneDeep(values)
			})

			this.form.validateFields((err, values) => {
				if (!err) {

					this.$store.dispatch('billing/CREATE_BILL', {
						...values,
						...verificationValues,
					}).then(response => {
						this.bill = {
							pdf: response.data.pdf,
							xml: response.data.xml,
						}
						Swal.fire({
							title: 'Facturación',
							text: response.message,
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							this.hasInvoice = true
						})
					}).catch(error => {
						Swal.fire({
							title: 'Facturación',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al validar el ticket. Solicita apoyo vía whatsapp al 55 6382 5173'),
							icon: 'error',
							confirmButtonText: 'Ok',
						}).then(() => {
							this.hasInvoice = false
						})
					})
				}
			})
		},
		async downloadBill(type) {
			const linkSource = (type == 'pdf' ? 'data:application/pdf;base64,' : 'data:text/xml;base64,') + this.bill[type]
			const downloadLink = document.createElement("a")
			const fileName = `factura.${type}`
			downloadLink.href = linkSource
			downloadLink.download = fileName
			downloadLink.click()
		},
		resetForm(e, form) {
			if (e) {
				e.preventDefault()
			}
			if (form == 'verification') {
				this.verificationForm.resetFields()
			} else {
				this.verificationForm.resetFields()
				this.form.resetFields()
				this.validTicket = false
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>