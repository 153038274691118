<template>
	<div>
		<billingComponent />
	</div>
</template>
<script>
import billingComponent from '@/components/billing'
export default {
	components: {
		billingComponent,
	},
}
</script>
