export default Object.freeze([
	{
		id: 'G01',
		description: 'Adquisición de mercancías',
	},
	{
		id: 'G03',
		description: 'Gastos en General',
	},
	{
		id: 'S01',
		description: 'Sin efectos fiscales',
	},
])
